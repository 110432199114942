/* eslint-disable no-restricted-syntax -- Legacy. */

import type { TRect } from '../canvas/definitions'

interface IVerticalCollisionData {
  isTopColliding: boolean
  isBottomColliding: boolean
  isContainsVertical: boolean
  isInsideVertical: boolean
}

interface IHorizontalCollisionData {
  isLeftColliding: boolean
  isRightColliding: boolean
  isContainsHorizontal: boolean
  isInsideHorizontal: boolean
}

interface ICollisionData extends IVerticalCollisionData, IHorizontalCollisionData {}

/**
 * Vertikális ütközéssel kapcsolatos levizsgálások kiértékelése.
 * @param rectOne - Első elem Rectje.
 * @param rectTwo - Másik elem Rectje.
 */
export function getVerticalCollisionData (rectOne: TRect, rectTwo: TRect): IVerticalCollisionData {
  const { bottom, top } = rectOne
  const { bottom: bottom2, top: top2 } = rectTwo

  const isTopColliding     = top <= bottom2 && top >= top2
  const isBottomColliding  = bottom <= bottom2 && bottom >= top2
  const isContainsVertical = top <= top2 && bottom >= bottom2
  const isInsideVertical   = top2 <= top && bottom2 >= bottom

  return {
    isTopColliding,
    isBottomColliding,
    isContainsVertical,
    isInsideVertical
  }
}

/**
 * Horizontális ütközéssel kapcsolatos levizsgálások kiértékelése.
 * @param rectOne - Első elem Rectje.
 * @param rectTwo - Másik elem Rectje.
 */
export function getHorizontalCollisionData (rectOne: TRect, rectTwo: TRect): IHorizontalCollisionData {
  const { left, right } = rectOne
  const { left: left2, right: right2 } = rectTwo

  const isLeftColliding      = left >= left2 && left <= right2
  const isRightColliding     = right >= left2 && right <= right2
  const isContainsHorizontal = left <= left2 && right >= right2
  const isInsideHorizontal   = left2 <= left && right2 >= right

  return {
    isLeftColliding,
    isRightColliding,
    isContainsHorizontal,
    isInsideHorizontal
  }
}

/**
 * Horizontálisan ütközik a két rect?
 * @param rectOne - Első elem Rectje.
 * @param rectTwo - Másik elem Rectje.
 */
export function isCollidingHorizontal (rectOne: TRect, rectTwo: TRect): boolean {
  const {
    isContainsHorizontal,
    isInsideHorizontal,
    isLeftColliding,
    isRightColliding
  } = getHorizontalCollisionData(rectOne, rectTwo)

  return (
    isLeftColliding ||
    isRightColliding ||
    isInsideHorizontal ||
    isContainsHorizontal
  )
}

/**
 * Vertikálisan ütközik a két rect?
 * @param rectOne - Első elem Rectje.
 * @param rectTwo - Másik elem Rectje.
 */
export function isCollidingVertical (rectOne: TRect, rectTwo: TRect): boolean {
  const {
    isBottomColliding,
    isContainsVertical,
    isInsideVertical,
    isTopColliding
  } = getVerticalCollisionData(rectOne, rectTwo)

  return (
    isTopColliding ||
    isBottomColliding ||
    isInsideVertical ||
    isContainsVertical
  )
}

/**
 * Ütközéssel kapcsolatos levizsgálások kiértékelése.
 * @param rectOne - Első elem Rectje.
 * @param rectTwo - Másik elem Rectje.
 */
export function getCollisionData (rectOne: TRect, rectTwo: TRect): ICollisionData {
  return {
    ...getVerticalCollisionData(rectOne, rectTwo),
    ...getHorizontalCollisionData(rectOne, rectTwo)
  }
}

/**
 * Összeér a két elem a Rectek alapján?
 * @param rectOne - Egyik elem rect-je.
 * @param rectTwo - Másik elem rect-je.
 */
export function isColliding (rectOne: TRect, rectTwo: TRect): boolean {
  return isCollidingVertical(rectOne, rectTwo) && isCollidingHorizontal(rectOne, rectTwo)
}
