/* eslint-disable no-restricted-syntax -- Legacy. */

import { is }     from '../is/is'
import { Array }  from '../Array/Array'
import { Object } from '../Object/Object'

export { cloneDeep } from './cloneDeep'

/**
 * Véletlenszerű értékek elkérése object-ből.
 * @param param   - Az object.
 * @param howMany - Hány értéket szeretnénk.
 */
export function chooseSomeFromObjLike<T> (
  param: Record<number | string | symbol, T> | readonly T[],
  howMany: number
): T[] {
  const values = [ ...(is.array(param) ? param : Object.getValues(param)) ]

  return Array.shuffle(values) // Object összes kulcsának keverése.
    .slice(0, howMany)   // Ebből levágni a kért mennyiséget.
}

/**
 * Két object különbségeinek kigyűjtése.
 * @param obj1      - Első Object.
 * @param obj2      - Második object.
 * @param wholePath - Az aktális mélység.
 */
function compare (obj1: TAnyObject, obj2: TAnyObject, wholePath = ''): string[] {
  const differences: string[] = []

  const keys       = Object.getKeys(obj1)
  const objKeys    = keys.filter((key) => is.object(obj1[key]))
  // string vagy number
  const notObjKeys = keys.filter((key) => !is.object(obj1[key]))

  /**
   * Error üzenet hozzáadása.
   * @param key   - A kulcs ahol a hiba van.
   * @param error - Hiba üzenete.
   */
  function addErrorMsg (key: string | number, error: 'ELTÉRÉS' | 'OBJ'): void {
    differences.push(`${ error }: ${ wholePath.length ? `${ wholePath }.` : '' }${ key }`)
  }

  notObjKeys.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      addErrorMsg(key, 'ELTÉRÉS')
    }
  })

  if (!is.empty(objKeys)) {
    objKeys.forEach((key) => {
      if (is.object(obj2[key])) {
        const newPath = `${ wholePath.length ? `${ wholePath }.` : '' }${ key }`
        const strings = compare(obj1[key], obj2[key], newPath)

        strings.forEach((str) => {
          differences.push(str)
        })
      }
      else {
        addErrorMsg(key, 'OBJ')
      }
    })
  }

  return differences
}

/**
 * Két object összehasonlítása.
 * @param obj1      - Első Object.
 * @param obj2      - Második object.
 * @param isBoolean - Részletes hibalista kérése.
 */
export function compareObject (obj1: TAnyObject, obj2: TAnyObject, isBoolean = true): string[] | boolean {
  const differences = compare(obj1, obj2)

  return isBoolean ? is.empty(differences) : differences
}
