/* eslint-disable custom/to-have-test-file -- Ennek nem kell tesztfájl. */

// UTILITY
export {
  debounce, randomBool,
  getRandomId
} from './utility'

export {
  isColliding, getCollisionData, isCollidingVertical,
  isCollidingHorizontal, getVerticalCollisionData, getHorizontalCollisionData
} from './collision'

// TIMING
export { wait, requestInterval } from './timing'

// Color
export { hexToRgba, getRandomHexColor } from './color'

// Object
export {
  cloneDeep, compareObject,
  chooseSomeFromObjLike
} from './object'
