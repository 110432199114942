/* eslint-disable custom/standalone-class */
import { URL } from '@common/URL/URL'
import { is } from '@common/is/is'
import type { TQuery } from '@common/Query/definitions'
import { EContentType, EHttpStatusCode } from '@common/http/definitions'
import type { TRequestMethod } from '@common/http/definitions'

import WorkingScreen from '@common/components/WorkingScreen/WorkingScreen'
import removeWorkingScreen from '@common/components/WorkingScreen/utils/removeWorkingScreen'

import getPostData from '../v1/getPostData'
import makeOnReadyStateChange from '../v1/makeOnReadyStateChange'
import type { TResult, TPostData } from '../v1/getPostData'

import { IS_ASYNC } from '../consts'
import setRequestHeaders from '../v1/setRequestHeaders'
import { setGlobalErrorHandler } from '../v1/globalErrorHandler'
import { setGlobalSuccessHandler } from '../v1/globalSuccessHandler'
import getAttributeValuePairsFromElement from '../v1/getAttributeValuePairsFromElement'

export interface IAjaxResult {
  readonly isSuccess: boolean
  readonly xhr: XMLHttpRequest
  readonly result: unknown
}

export interface IAjaxParams {
  readonly data?: TPostData
  readonly query?: TQuery
  readonly method?: TRequestMethod
  readonly hasLoadingScreen?: boolean
  readonly corsOrigin?: string
  readonly needXrw?: boolean
  readonly authorization?: string
}

export class Ajax {
  public static debugMode = true
  public static hasLoadingScreen = false
  public static loadingScreenTitle = 'Kis türelmet'
  public static setGlobalErrorHandler = setGlobalErrorHandler
  public static setGlobalSuccessHandler = setGlobalSuccessHandler
  public static getAttributeValuePairsFromElement = getAttributeValuePairsFromElement
  public static readonly STATUS_CODE    = EHttpStatusCode
  public static defaultContentType      = EContentType.FormUrlEncoded

  private readonly params?: IAjaxParams

  /**
   * Ezzel adjuk meg a globális errorhandlernek, hogy kell-e foglalkoznia a hibával.
   * Ha ez FALSE, akkor fog vele foglalkozni, egyébként nem.
   */
  private readonly isErrorTreated: boolean

  private url: string

  // Vannak definiálva, csak függvényen keresztül.
  private data: TResult | null = null
  private method: TRequestMethod = 'GET'

  /**
   * AJAX.
   * @param url            - Az URL, ahova lőjjük a request-et.
   * @param params         - A lehetséges paraméterek.
   * @param isErrorTreated - Mi kezeljük a hibát? Alapértelmezetten nem, tehát FALSE. Ha te szeretnéd kezelni a hibát, TRUE.
   */
  public constructor (url: string, params?: IAjaxParams, isErrorTreated = false) {
    this.url = url

    if (params) {
      this.params = params
    }

    this.setUrl(url, params?.query)

    this.setMethod(params?.method ?? 'GET')

    if (params?.data) {
      this.setData(params.data)
    }

    this.isErrorTreated = isErrorTreated
  }

  /** Az ajax küldése a szervernek. */
  public send (): Promise<IAjaxResult> {
    if (Ajax.hasLoadingScreen && this.params?.hasLoadingScreen !== false) {
      WorkingScreen(Ajax.loadingScreenTitle, '')
    }

    return new Promise((resolve) => {
      const { data, isErrorTreated, method, params, url } = this

      const xhr = new XMLHttpRequest()

      /**
       * Hiba esetén ebbe a callback-be fut bele.
       * @param result - A szervertől kapott válasz.
       */
      function onError (result: unknown): boolean { // eslint-disable-line no-restricted-syntax -- Legacy.
        removeWorkingScreen()

        resolve({
          isSuccess: false,
          result,
          xhr
        })

        return isErrorTreated
      }

      /**
       * Sikeres [200] esetén ebbe a callback-be fut.
       * @param result - A szervertől kapott válasz, amit a v1 ajax megpróbált JSON parseolni.
       */
      function onSuccess (result: unknown): void { // eslint-disable-line no-restricted-syntax -- Legacy.
        removeWorkingScreen()

        resolve({
          isSuccess: true,
          result,
          xhr
        })
      }

      // https://stackoverflow.com/questions/34905828/xmlhttprequest-onreadystatechange-vs-addeventlistener
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      xhr.onreadystatechange = makeOnReadyStateChange(xhr, onError, onSuccess)

      xhr.open(method, url, IS_ASYNC) // Nem támogatunk szinkron / blokkoló hívást.

      // Közvetlen küldés előtt állítsuk be a headereket.
      const needXrw = is.defined(params) && is.boolean(params.needXrw)
        ? params.needXrw
        : false

      setRequestHeaders(xhr, data, needXrw, params?.corsOrigin, Ajax.defaultContentType)

      // [MÁGUS]: egyelőre idetettem ezt a részt teszt gyanánt.
      // Ennek át kellene majd kerülnie a `setRequestHeaders` függvénybe,
      // csak ott már most túl sok argumentum van.
      if (params?.authorization) {
        xhr.setRequestHeader('Authorization', params.authorization)
      }

      xhr.send(data as any) // eslint-disable-line @typescript-eslint/no-explicit-any

      if (Ajax.debugMode) {
        // eslint-disable-next-line no-console
        console.log(`[AJAX] url: ${ url }, method: ${ method }, data:`, data)
      }
    })
  }

  /**
   * A szerver URL címének beállítása.
   * Az ajax ide lesz kilőve.
   * @param url   - A szerver címe.
   * @param query - Opcionális query paraméter.
   */
  public setUrl (url: string, query?: TQuery): void {
    this.url = URL.make(url, query)
  }

  /**
   * A szervernek küldendő adat beállítása.
   * @param data - A szervernek küldendő adat.
   */
  public setData (data: TPostData): void {
    this.data = getPostData(data)

    this.method = is.string(this.data) || is.instanceOf(this.data, FormData)
      ? 'POST'
      : this.method
  }

  /**
   * A request method beállítása.
   * @param method - A request method.
   */
  public setMethod (method: TRequestMethod): void {
    this.method = method
  }
}

/**
 * A class-alapú ajax factory-ja.
 * @param url            - Az url, amit hívni szeretnénk.
 * @param params         - A paraméterek, amiket küldünk a szervernek.
 * @param isErrorTreated - Mi kezeljük a hibát? Alapértelmezetten nem, tehát FALSE. Ha te szeretnéd kezelni a hibát, TRUE.
 */
export default function ajax (url: string, params?: IAjaxParams, isErrorTreated = false): Promise<IAjaxResult> {
  const instance = new Ajax(url, params, isErrorTreated)

  return instance.send()
}

ajax.getAttributeValuePairsFromElement = getAttributeValuePairsFromElement
