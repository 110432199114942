import { baseGlobals } from '@root/typescript/baseGlobals'
import { debounce } from '@common/utils/utils'

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const DEFAULT_DEBOUNCE_TIME = baseGlobals.isTest ? 0 : 10

interface IOptions {
  readonly debounceTime?: number
  readonly onlyOnce?: boolean
}

/**
 * A scroll esemény figyelése.
 * A callback azért void és nem adja vissza az aktuális scroll poziciót, mert a document.documentElement.scrollTop reflowt eredményez.
 * Ha valahol valakinek szüksége van rá, akkor majd lekérdezi.
 * @see {@link https://www.30secondsofcode.org/js/s/passive-scroll-listener-performance/}
 * @param callback - A scrollozáskor hívódó callback.
 * @param options  - A lehetséges beállítások.
 */
export default function onScroll (callback: TVoidCallback, options?: IOptions): void {
  const debounceTime = options?.debounceTime ?? DEFAULT_DEBOUNCE_TIME

  /** Vagy debounceolt állapot, vagy az eredeti. */
  const callable = debounceTime > 0
    ? debounce(() => callback(), debounceTime)
    : callback

  /** Befogjuk az egészet egy új függvénybe. */
  function wrapper (): void {
    callable()

    if (options?.onlyOnce) {
      window.removeEventListener('scroll', wrapper)
    }
  }

  window.addEventListener('scroll', wrapper, { passive: true })
}
