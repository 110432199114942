/* eslint-disable no-restricted-syntax -- Legacy. */

import { Number } from '../Number/Number'

/**
 * Hexadecimális színkód átalakítása RGBA-ra.
 * @param hex     - Hex színkód.
 * @param opacity - A szín opacitása.
 */
export function hexToRgba (hex: string, opacity = 1): string {
  if ((/^#([\dA-Fa-f]{3}){1,2}$/).test(hex)) {
    let x = hex.slice(1).split('')

    if (x[0] && x[1] && x[2]) {
      x = [ x[0], x[0], x[1], x[1], x[2], x[2] ]
    }

    const c = Number.parseInt(`0x${ x.join('') }`)

    return `rgba(${ [ (c >> 16) & 255, (c >> 8) & 255, c & 255 ].join(',') },${ opacity })`
  }

  return ''
}

/** Véletlenszerű szín generálása. */
function getRandomColor (): string {
  const letters = '0123456789ABCDEF'
  let color = ''

  for (let i = 0; i < 6; i++) {
    color = color + letters[Math.floor(Math.random() * 16)]
  }

  return color
}

/**
 * Véletlenszerű hexadecimális színkód generálása.
 * @param needHashmark - Hashmark a visszaadott string-ben.
 */
export function getRandomHexColor (needHashmark = true): string {
  return `${ needHashmark ? '#' : '' }${ getRandomColor() }`
}

/*
export function rgbToHex (rgb: string): string {
  const hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  return "#" + hex
}
*/

/**
 * Visszaadja, hogy egy string hex color-e.
 * @param color - A szöveg, amit vizsgálunk.
 */
export function isHexColor (color: string): boolean {
  return (/(^#[\da-f]{6}$)|(^#[\da-f]{3}$)/i).test(color)
}
