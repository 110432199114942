/* eslint-disable no-restricted-syntax -- Legacy. */

import { baseGlobals } from '@root/typescript/baseGlobals'

/**
 * Időzítő/késleltető Promise-os függvényünk.
 * @param time - Hány ms után legyen resolve.
 */
export function wait (time: number): Promise<void> {
  return new Promise((resolve) => {
    if (baseGlobals.isTest) {
      resolve()
    }
    else {
      // eslint-disable-next-line no-restricted-globals -- Backend/frontend környezetben miatt ez kell ide.
      setTimeout(resolve, time)
    }
  })
}

type TCallback = () => (void | false)

/**
 * Egyedi időzítő függvényünk.
 * @param callback - Intervalonként megfutó callback.
 * @param delay    - Interval delay.
 * @param times    - Hányszor fusson meg.
 */
export function requestInterval (callback: TCallback, delay: number, times: number): Promise<void> {
  let start = Date.now()
  let loopIndex = 0

  return new Promise((resolve) => {
    /** Ez kezeli le, hogy mi történjen minden interval megfutásakor. */
    function loop (): void {
      const now = Date.now()
      const delta = now - start

      let canContinue = true

      if (delta >= delay) {
        loopIndex++
        const willContinue = callback()
        canContinue = willContinue !== false
        start = Date.now()
      }

      if (!canContinue) {
        resolve()
      }
      else if (loopIndex < times) {
        window.requestAnimationFrame(loop)
      }
      else {
        resolve()
      }
    }

    loop()
  })
}
