import { is } from '@common/is/is'

import getTrans from './getTrans'
import languageHandler from './languageHandler'

/**
 * Ezzel tudunk saját fallback-et adni, ha nincs semmilyen nyelvben sem megfelelő.
 * @param id       - Ami alapján keresünk.
 * @param fallback - Fallback szöveg.
 */
export default function getTransByIdWithFallback (id: string, fallback: string): string {
  const res = getTrans(id, languageHandler.getLang())

  if (is.null(res)) {
    return fallback
  }

  return res
}
