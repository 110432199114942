import { is } from '../is/is'

import { Object } from '../Object/Object'

import getNamespacedKey from './utils/getNamespacedKey'

export interface IStorage<TKey extends string = string> {
  readonly get: (key: TKey) => string | null
  readonly set: (key: TKey, value: string) => void
  readonly has: (key: TKey) => boolean
  readonly remove: (key: TKey) => void
  readonly clear: TVoidCallback
}

/**
 * Namespacelt wrapper a natív localStorage fölött.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage}
 */
export default class Storage<TKey extends string = string> implements IStorage<TKey> {
  private readonly ns: string
  private readonly storage: typeof window.localStorage

  /**
   * Storage példányosítása.
   * @param namespace   - A namespace, amikhez párosítjuk a kulcs-értékeket.
   * @param storageType - Milyen típusú storage-ot szeretnénk példányosítani.
   */
  public constructor (namespace = '', storageType: 'local' | 'session' = 'local') {
    this.ns = namespace
    // eslint-disable-next-line no-restricted-properties -- Itt kell a két storage.
    this.storage = storageType === 'local' ? window.localStorage : window.sessionStorage
  }

  /**
   * Egy kulcshoz tartozó érték elkérése.
   * @param key - A kulcs, aminek az értékét kérjük.
   */
  public get (key: TKey): string | null {
    return this.storage.getItem(getNamespacedKey(this.ns, key))
  }

  /**
   * Egy kulcs-érték páros megadása.
   * @param key   - A kulcs, amihez az értéket párosítjuk.
   * @param value - Az érték, ami a kulcshoz tartozik.
   */
  public set (key: TKey, value: string): void {
    this.storage.setItem(getNamespacedKey(this.ns, key), value)
  }

  /**
   * Adott kulcs alapján létezik érték?
   * @param key - A kulcs, amit vizsgálunk.
   */
  public has (key: TKey): boolean {
    return !is.null(this.storage.getItem(getNamespacedKey(this.ns, key)))
  }

  /**
   * Egy érték törlése, kulcs alapján.
   * @param key - A kulcs, ami alapján törölni szeretnénk.
   */
  public remove (key: TKey): void {
    delete this.storage[getNamespacedKey(this.ns, key)] // eslint-disable-line @typescript-eslint/no-dynamic-delete -- Muszáj törölni.
  }

  /**
   * Ha létezik egy érték.
   * @param key   - A kulcs, amit állapottól függően beállítunk vagy törlünk.
   * @param value - A kulcshoz tartozó érték.
   */
  public toggle (key: TKey, value: string): void {
    if (this.has(key)) {
      this.remove(key)
    }
    else {
      this.set(key, value)
    }
  }

  /** Az adott namespace-hez tartozó összes érték törlése. */
  public clear (): void {
    Object.getKeys(this.storage).forEach((key) => {
      const strKey = key as unknown as string

      if (strKey.startsWith(this.ns)) {
        delete this.storage[key] // eslint-disable-line @typescript-eslint/no-dynamic-delete -- Muszáj törölni.
      }
    })
  }
}
